<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/goods' }">商品列表</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/SpeGoods' }"
        >商品规格管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>添加商品规格</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card> </el-card>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
